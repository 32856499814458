import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import {Skeleton, Stack} from '@mui/material'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoCaption} from '../../../shared/components/InfoCaption'
import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {useHistoryMaterialsById} from '../../../shared/hooks/api/materials/useHistoryMaterialsById'
import {useProducedAndForecastedMaterials} from '../../../shared/hooks/api/producedAndForecastedMaterials/useProducedAndForecastedMaterials'

import {ProductionSummaryCardHeader} from './ProductionSummaryCardHeader'
import {ProductionSummaryTable} from './ProductionSummaryTable'

const PRODUCTION_SUMMARY_TABLE_SKELETON_HEIGHT = 300

export const ProductionSummaryCard: React.FC = () => {
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }

  const {data: producedAndForecastedMaterialData} = useProducedAndForecastedMaterials({
    timeFrame: selectedTimeframe
  })

  const {data: materialsById} = useHistoryMaterialsById({timeFrame: selectedTimeframe})

  return (
    <Card
      headerContent={<ProductionSummaryCardHeader />}
      {...dataTestId('production_summary_card')}
    >
      <Stack spacing={2}>
        <InfoCaption>
          <Trans i18nKey="planning.productionSummaryDescription" components={{1: <strong />}} />
        </InfoCaption>
        {producedAndForecastedMaterialData ? (
          <ProductionSummaryTable
            data={producedAndForecastedMaterialData}
            materialsById={materialsById}
          />
        ) : (
          <Skeleton variant="rectangular" height={PRODUCTION_SUMMARY_TABLE_SKELETON_HEIGHT} />
        )}
      </Stack>
    </Card>
  )
}
