import {dayNameFormatter} from '@hconnect/uikit/src/common'
import {TFunction} from 'i18next'
import {Moment} from 'moment-timezone'

import {ReachVariant} from '../../../../shared/enums'
import type {MaterialInfo} from '../../../../shared/selectors/stock'
import {StockReachState} from '../../../../shared/selectors/stockReach'
import {
  adjustEnLocale,
  getDayAndMonthWithTimezone,
  getPlantUtcOffset
} from '../../../../shared/selectors/time'

export const convertHoursToDaysAndHours = (amountHours: number): [days: number, hours: number] => {
  const days = Math.floor(amountHours / 24)
  const hours = Math.round(amountHours - days * 24)
  return [days, hours]
}

export const formatReach = (materialInfo: MaterialInfo, currentTime: Moment, t: TFunction) => {
  if (materialInfo.reachDate === undefined) {
    throw new Error('Cannot format reach which has not been yet loaded')
  }

  const isMaxReachExceeded = materialInfo.reachDate.type === StockReachState.Future

  const hoursDiff = Math.max(materialInfo.reachDate.date.diff(currentTime, 'hours'), 0)
  const [days, hours] = convertHoursToDaysAndHours(hoursDiff)
  return `${t('stock.reachDays', {prefix: isMaxReachExceeded ? '> ' : '', days})}${
    hours ? ` ${t('stock.reachHours', {hours})}` : ''
  }`
}

export const formatReachDate = (
  reachDate: Moment,
  timezoneId: string,
  language: string
): string => {
  return `${dayNameFormatter(
    reachDate,
    language,
    getPlantUtcOffset(timezoneId, reachDate)
  )}, ${getDayAndMonthWithTimezone(reachDate, timezoneId, adjustEnLocale(language))}`
}

export const reachToVariant = (reachInDays?: number): ReachVariant => {
  if (reachInDays === undefined) {
    return ReachVariant.other
  }
  if (reachInDays > 7) {
    return ReachVariant.ok
  }
  if (reachInDays > 3) {
    return ReachVariant.info
  }

  return ReachVariant.warning
}
