import {Box, Typography, useTheme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {usePlanningChartStartEnd} from '../../../../app/page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {useDateScale} from '../../../helpers/scale'
import {toPixel} from '../../../helpers/utils'
import {CurrentTimeIndicator} from '../CurrentTimeIndicator'

type ChartLabelIndicatorProps = {
  hoursDisplayed: number
  cellWidth: number
  time: Moment
  label: string
  backgroundColor: string
  color: string
}

export const ChartDateIndicator: React.FC<ChartLabelIndicatorProps> = ({
  hoursDisplayed,
  cellWidth,
  time,
  label,
  backgroundColor,
  color
}) => {
  const theme = useTheme()
  const {startOfChart, endOfChart} = usePlanningChartStartEnd()
  const width = hoursDisplayed * cellWidth

  const scale = useDateScale({
    domain: [startOfChart, endOfChart],
    range: [0, width]
  })

  if (time.isBefore(startOfChart) || time.isAfter(endOfChart)) {
    return null
  }

  const xOffset = scale(time)

  const isLabelToRight = xOffset + 100 < width
  const borderRadiusRight = theme.spacing(isLabelToRight ? 0.5 : 0)
  const borderRadiusLeft = theme.spacing(isLabelToRight ? 0 : 0.5)

  return (
    <>
      <Box
        sx={{
          backgroundColor,
          color,
          position: 'absolute',
          top: 0,
          left: isLabelToRight ? toPixel(xOffset) : undefined,
          right: isLabelToRight ? undefined : toPixel(width - xOffset),
          paddingRight: (theme) => theme.spacing(1),
          paddingLeft: (theme) => theme.spacing(1),
          zIndex: 1,
          borderRadius: `${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`
        }}
      >
        <Typography variant="body2">{label}</Typography>
      </Box>
      <CurrentTimeIndicator xOffset={xOffset} color={backgroundColor} />
    </>
  )
}
