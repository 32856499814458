import {Material, MaterialType} from '@hconnect/common/types'

import {AssetCapacity, AssetCapacityResponse, DemandDataPerMaterial} from '../interfaces/api'

/**
 * function to aggregate produced materials per material
 * from asset capacity data
 * @param assetsCapacity
 * @returns dictionary of material id and produced tonnes
 */

export const getProducedPerMaterial = (
  assetsCapacity: AssetCapacityResponse['assets']
): Record<string, number> => {
  const assetProductionKPIs: AssetCapacity['materials'][] = Object.values(assetsCapacity).map(
    (assetCapacity) => assetCapacity.materials
  )
  const aggregatedProducedMaterials = assetProductionKPIs.reduce<Record<string, number>>(
    (result, materialCapacity) => {
      Object.entries(materialCapacity).forEach(([materialId, {producedTonnes}]) => {
        result[materialId] = (result[materialId] ?? 0) + producedTonnes
      })
      return result
    },
    {}
  )
  return aggregatedProducedMaterials
}

/**
 * Function to find produced materialIds and filter by material type
 * all materials should be in materialById, otherwise it will throw an error
 * @param producedByMaterialId
 * @param forecastedMaterials
 * @param materialsById
 * @param types - material types to filter by
 */
export const getProducedMaterialsIds = (
  producedByMaterialId: Record<string, number>,
  materialsById: Record<string, Material>,
  types: MaterialType[]
) => {
  return Object.keys(producedByMaterialId).filter((materialId) => {
    const material = materialsById[materialId]
    if (!material) {
      throw new Error(`BUG: Material with id ${materialId} does not exist in materialsById`)
    }
    return types.includes(materialsById[materialId].type)
  })
}

/**
 * Function to find produced or forecasted materialIds and filter by material type
 * all materials should be in materialById, otherwise it will throw an error
 * @param producedByMaterialId
 * @param forecastedMaterials
 * @param materialsById
 * @param types - material types to filter by
 */
export const getProducedOrForecastedMaterialsIds = (
  producedByMaterialId: Record<string, number>,
  forecastedMaterials: DemandDataPerMaterial,
  materialsById: Record<string, Material>,
  types: MaterialType[]
) => {
  return [
    ...new Set([
      ...getProducedMaterialsIds(producedByMaterialId, materialsById, types),
      ...Object.keys(forecastedMaterials)
    ])
  ]
}
