import {useTheme} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantConfig} from '../../../hooks/usePlantConfigData'
import {ScheduleOtimizationRange} from '../../../interfaces/api'

import {ChartDateIndicator} from './ChartDateIndicator'

type ChartOptimizedPlanStartEndIndicatorProps = {
  hoursDisplayed: number
  cellWidth: number
  optimizationRange: ScheduleOtimizationRange
}

export const ChartOptimizedPlanStartEndIndicator: React.FC<
  ChartOptimizedPlanStartEndIndicatorProps
> = ({hoursDisplayed, cellWidth, optimizationRange}) => {
  const theme = useTheme()
  const {timezone_id: timezoneId} = usePlantConfig()
  const {t} = useTranslation()

  const from = moment.utc(optimizationRange.from).tz(timezoneId)
  const to = moment.utc(optimizationRange.to).tz(timezoneId)

  return (
    <>
      <ChartDateIndicator
        label={t('optimizer.optimizedPlanStarts', {
          time: from.format('HH:mm')
        })}
        hoursDisplayed={hoursDisplayed}
        cellWidth={cellWidth}
        time={from}
        backgroundColor={theme.palette.primary.main}
        color={theme.palette.background.paper}
      />
      <ChartDateIndicator
        label={t('optimizer.optimizedPlanEnds', {
          time: to.format('HH:mm')
        })}
        hoursDisplayed={hoursDisplayed}
        cellWidth={cellWidth}
        time={to}
        backgroundColor={theme.palette.primary.main}
        color={theme.palette.background.paper}
      />
    </>
  )
}
