import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NOW_INDICATOR_REFRESH_INTERVAL_MS} from '../../../constants'
import {useCurrentTime} from '../../../hooks/useCurrentTime'
import {usePlantConfig} from '../../../hooks/usePlantConfigData'

import {ChartDateIndicator} from './ChartDateIndicator'

type ChartNowIndicatorProps = {
  hoursDisplayed: number
  cellWidth: number
  shouldRoundCurrentTime: boolean
}

export const ChartNowIndicator: React.FC<ChartNowIndicatorProps> = ({
  hoursDisplayed,
  cellWidth,
  shouldRoundCurrentTime
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()

  const currentTime = useCurrentTime({
    timezoneId,
    intervalMs: NOW_INDICATOR_REFRESH_INTERVAL_MS
  })
  const currentTimeAfterRounding = shouldRoundCurrentTime
    ? roundTo15MinIntervalStart(currentTime)
    : currentTime

  return (
    <ChartDateIndicator
      label={t('common.nowWithTime', {time: currentTimeAfterRounding.format('HH:mm')})}
      hoursDisplayed={hoursDisplayed}
      cellWidth={cellWidth}
      time={currentTimeAfterRounding}
      backgroundColor={theme.palette.text.primary}
      color={theme.palette.background.paper}
    />
  )
}
