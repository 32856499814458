import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import moment from 'moment-timezone'
import {useCallback} from 'react'

import {StockReachResponse} from '../../../interfaces/api'
import {stockReachDataSelector} from '../../../selectors/stockReach'
import {useCurrentTimeRounded} from '../../useCurrentTimeRounded'
import {usePlantConfig} from '../../usePlantConfigData'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface StockReachParams {
  materialIds: number[] | undefined
}

const MAX_REACH_IN_DAYS = 30

export const useStockReachQuery = ({materialIds = []}: StockReachParams) => {
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId} = usePlantConfig()

  const endTime = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})
    .clone()
    .add(MAX_REACH_IN_DAYS, 'day')
    .toISOString()

  const selector = useCallback(
    (response: StockReachResponse) =>
      stockReachDataSelector(response, timezoneId, moment(endTime).tz(timezoneId)),
    [timezoneId, endTime]
  )

  return usePlannerQuery(
    'stockReach',
    [
      {
        plantCode,
        materialIds,
        to: endTime
      }
    ],
    {enabled: Boolean(materialIds.length), select: selector}
  )
}
