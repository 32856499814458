import {HistoryStorageWithMaterial, MaterialType, StorageWithMaterial} from '@hconnect/common/types'

import {StockReachData, StockReachValue} from './stockReach'

export interface MaterialInfo {
  materialId: string
  materialType: MaterialType
  capacity: number
  level: number
  reachDate: StockReachValue | undefined // undefined is not loaded yet, null is reach unknown
  storages: HistoryStorageWithMaterial[]
}

interface GetStorageToMaterialAmountParams {
  storagesWithMaterial: StorageWithMaterial[]
  stockReach: StockReachData | undefined
}
/**
 * assembles data as needed by the Stock Page
 */
export const getStorageToMaterialAmount = ({
  storagesWithMaterial,
  stockReach
}: GetStorageToMaterialAmountParams): MaterialInfo[] => {
  const materialDetails: Record<string, Omit<MaterialInfo, 'materialId'> & {deadStock: number}> = {}

  for (const storage of storagesWithMaterial) {
    if (!(storage.materialId in materialDetails)) {
      materialDetails[storage.materialId] = {
        capacity: 0,
        level: 0,
        storages: [],
        deadStock: 0,
        materialType: storage.materialType,
        reachDate: stockReach ? stockReach[storage.materialId] : undefined
      }
    }
    materialDetails[storage.materialId].level += storage.currentStorageLevel.level
    materialDetails[storage.materialId].deadStock += storage.deadStock
    materialDetails[storage.materialId].capacity += storage.capacity
    materialDetails[storage.materialId].storages.push({...storage})
  }

  return Object.keys(materialDetails).map((k) => ({
    materialId: k,
    ...materialDetails[k]
  }))
}
