import {AttachmentFile} from '@hconnect/common/types'
import {attachmentFilesToFormData} from '@hconnect/common/utils'
import {kebabCase} from 'lodash'

import {CommentsCategory, OptimizerCalculationAction, ShippingType} from '../enums'
import type {
  ScheduleItem,
  Schedule,
  SubmitSchedule,
  Comment,
  CreateEditComment,
  ScheduleCostAvoidance,
  CreateMaterialOrder,
  MaterialOrder,
  EditMaterialOrder,
  CreateUpdateScheduleItem,
  UpdateScheduleItemResponse
} from '../interfaces/api'

import {ApiClient, dsApiBasePath} from './apiClient'
import type {AsyncFn} from './apiClient'

export interface Mutations {
  //  C# Endpoints
  selectSchedule: AsyncFn<[{plantCode: string}], Schedule>
  rejectSchedule: AsyncFn<[{plantCode: string}], Record<string, never>>
  addScheduleItem: AsyncFn<
    [{plantCode: string; scheduleId: number; scheduleItemDTO: CreateUpdateScheduleItem}],
    void
  >
  updateScheduleItem: AsyncFn<
    [
      {
        plantCode: string
        scheduleId: number
        scheduleItemDTO: CreateUpdateScheduleItem
        scheduleItemId: string
      }
    ],
    UpdateScheduleItemResponse
  >
  deleteScheduleItem: AsyncFn<
    [{plantCode: string; scheduleId: number; scheduleItem: ScheduleItem}],
    void
  >
  submitElectricityPlan: AsyncFn<
    [{plantCode: string; scheduleId: number; updatedById: string; updatedOn: string}],
    SubmitSchedule
  >
  sendBackupPlan: AsyncFn<[{plantCode}], void>
  addComment: AsyncFn<
    [
      {
        plantCode: string
        commentsCategory: CommentsCategory
        commentsCategoryInstanceId: string
        commentDto: CreateEditComment
      }
    ],
    Comment
  >
  editComment: AsyncFn<
    [
      {
        plantCode: string
        commentsCategory: CommentsCategory
        commentsCategoryInstanceId: string
        commentId: string
        commentDto: CreateEditComment
      }
    ],
    void
  >
  deleteComment: AsyncFn<
    [
      {
        plantCode: string
        commentsCategory: CommentsCategory
        commentsCategoryInstanceId: string
        commentId: string
      }
    ],
    void
  >
  calculateOptimizedPlanning: AsyncFn<
    [{plantCode: string; status: OptimizerCalculationAction}],
    void
  >
  dispatchAutofill: AsyncFn<
    [{plantCode: string; config: {startTime: string; endTime: string}}],
    void
  >
  calculateCostAvoidance: AsyncFn<[{plantCode: string; scheduleId: number}], ScheduleCostAvoidance>
  addMaterialOrder: AsyncFn<[{plantCode: string; dto: CreateMaterialOrder}], MaterialOrder>
  editMaterialOrder: AsyncFn<
    [
      {
        plantCode: string
        materialOrderId: number
        editedOrder: EditMaterialOrder
        property: keyof EditMaterialOrder
      }
    ],
    MaterialOrder
  >
  cancelMaterialOrder: AsyncFn<[{plantCode: string; materialOrderId: number}], void>
  deleteMaterialOrder: AsyncFn<[{plantCode: string; materialOrderId: number}], void>
  createStockLevelOverwrite: AsyncFn<
    [{plantCode: string; materialId: number; date: string; level: number}],
    void
  >
  addMaterialOrderAttachments: AsyncFn<
    [{plantCode: string; materialOrderId: number; attachments: AttachmentFile[]}],
    void
  >
  deleteMaterialOrderAttachments: AsyncFn<
    [{plantCode: string; materialOrderId: number; attachmentIds: number[]}],
    void
  >
  createDemandOverride: AsyncFn<
    [
      {
        plantCode: string
        materialId: number
        date: string
        shippingType: ShippingType
        quantity: number
      }
    ],
    void
  >
}

export const mutations: Mutations = {
  //  C# Endpoints
  selectSchedule: async ({plantCode}) => {
    const path = '/schedules/select'
    const params: Record<string, unknown> = {plantCode}
    const response = await ApiClient.axiosInstance.put<Schedule>(path, {}, {params})
    return response.data
  },
  rejectSchedule: async ({plantCode}) => {
    const path = '/schedules/reject'
    const params: Record<string, unknown> = {plantCode}
    const response = await ApiClient.axiosInstance.put<Record<string, never>>(path, {}, {params})
    return response.data
  },
  addScheduleItem: async ({scheduleId, plantCode, scheduleItemDTO: scheduleItem}) => {
    const path = `/schedules/${scheduleId}/items`
    const params: Record<string, unknown> = {plantCode}
    await ApiClient.axiosInstance.post<void>(path, scheduleItem, {params})
  },
  updateScheduleItem: async ({scheduleId, plantCode, scheduleItemDTO, scheduleItemId}) => {
    const path = `/schedules/${scheduleId}/items/${scheduleItemId}`
    const params: Record<string, unknown> = {plantCode}
    const response = await ApiClient.axiosInstance.put<UpdateScheduleItemResponse>(
      path,
      scheduleItemDTO,
      {params}
    )
    return response.data
  },
  deleteScheduleItem: async ({scheduleId, plantCode, scheduleItem}) => {
    const path = `/schedules/${scheduleId}/items/${scheduleItem.id}`
    const params: Record<string, unknown> = {plantCode}
    await ApiClient.axiosInstance.delete(path, {params})
  },
  submitElectricityPlan: async ({scheduleId, plantCode, updatedById, updatedOn}) => {
    const path = '/electricity'
    const params: Record<string, unknown> = {
      plantCode,
      scheduleId,
      updatedBy: updatedById,
      updatedOn
    }
    const response = await ApiClient.axiosInstance.post<SubmitSchedule>(path, {}, {params})
    return response.data
  },
  sendBackupPlan: async ({plantCode}) => {
    const path = '/electricity/production-planning-backup'
    const params: Record<string, unknown> = {plantCode}
    const response = await ApiClient.axiosInstance.post<void>(path, {}, {params})
    return response.data
  },
  addComment: async ({plantCode, commentsCategory, commentsCategoryInstanceId, commentDto}) => {
    const path = `/comments/${plantCode}/${commentsCategory}/${commentsCategoryInstanceId}`
    const response = await ApiClient.axiosInstance.post<Comment>(path, commentDto)
    return response.data
  },
  editComment: async ({
    plantCode,
    commentsCategory,
    commentsCategoryInstanceId,
    commentId,
    commentDto
  }) => {
    const path = `/comments/${plantCode}/${commentsCategory}/${commentsCategoryInstanceId}/${commentId}`
    await ApiClient.axiosInstance.put<void>(path, commentDto)
  },
  deleteComment: async ({plantCode, commentsCategory, commentsCategoryInstanceId, commentId}) => {
    const path = `/comments/${plantCode}/${commentsCategory}/${commentsCategoryInstanceId}/${commentId}`
    await ApiClient.axiosInstance.delete<void>(path)
  },
  calculateOptimizedPlanning: async ({plantCode, status}) => {
    const path = `${dsApiBasePath}/v2/optimizer/${plantCode}/status`
    await ApiClient.axiosInstance.patch<void>(path, {status})
  },
  dispatchAutofill: async ({plantCode, config}) => {
    const path = `${dsApiBasePath}/v2/autofill/${plantCode}`
    await ApiClient.axiosInstance.post<void>(path, {
      start_time: config.startTime,
      end_time: config.endTime
    })
  },
  calculateCostAvoidance: async ({plantCode, scheduleId}) => {
    const path = `/plants/${plantCode}/schedules/${scheduleId}/kpi/cost-avoidance`
    const response = await ApiClient.axiosInstance.get<ScheduleCostAvoidance>(path)
    return response.data
  },
  addMaterialOrder: async ({plantCode, dto}) => {
    const path = `/plants/${plantCode}/material-orders`
    const response = await ApiClient.axiosInstance.post<MaterialOrder>(path, dto)
    return response.data
  },
  editMaterialOrder: async ({plantCode, materialOrderId, editedOrder, property}) => {
    const path = `/plants/${plantCode}/material-orders/${materialOrderId}/${kebabCase(property)}`
    const response = await ApiClient.axiosInstance.patch<MaterialOrder>(path, {
      [property]: editedOrder[property]
    })
    return response.data
  },
  cancelMaterialOrder: async ({plantCode, materialOrderId}) => {
    const path = `/plants/${plantCode}/material-orders/${materialOrderId}/cancel`
    await ApiClient.axiosInstance.patch<void>(path)
  },
  deleteMaterialOrder: async ({plantCode, materialOrderId}) => {
    const path = `/plants/${plantCode}/material-orders/${materialOrderId}`
    await ApiClient.axiosInstance.delete<void>(path)
  },
  addMaterialOrderAttachments: async ({plantCode, materialOrderId, attachments}) => {
    const path = `/plants/${plantCode}/material-orders/${materialOrderId}/attachments`
    const formData = new FormData()
    await attachmentFilesToFormData(attachments, formData)
    await ApiClient.axiosInstance.post<void>(path, formData)
  },
  deleteMaterialOrderAttachments: async ({plantCode, materialOrderId, attachmentIds}) => {
    const getPath = (attachmentId: number) =>
      `/plants/${plantCode}/material-orders/${materialOrderId}/attachments/${attachmentId}`
    await Promise.all(attachmentIds.map((id) => ApiClient.axiosInstance.delete<void>(getPath(id))))
  },
  createStockLevelOverwrite: async ({plantCode, materialId, date, level}) => {
    const path = `/plants/${plantCode}/material-storage/stock-level/material/${materialId}`
    await ApiClient.axiosInstance.post<void>(path, {date, level})
  },
  createDemandOverride: async ({materialId, date, shippingType, plantCode, quantity}) => {
    const path = `/plants/${plantCode}/material-demand/${materialId}/demand/overrides/${date}/shipping/${shippingType}`
    const response = await ApiClient.axiosInstance.post<void>(path, {quantity})
    return response.data
  }
}
