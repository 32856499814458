import {debounce} from 'lodash'
import {useEffect, useMemo, useRef} from 'react'

import {AUTOSAVE_INTERVAL_MS} from '../constants'

interface AutoSaveValueParams<T extends string | number> {
  currentValue: T
  shouldSave: boolean
  saveFn: () => Promise<void> | void
  saveOnDestroy?: boolean
  interval?: number
}

/**
 * hook which saves the value when it changes and shouldSave is true
 * it debounces the save function with interval
 */
export const useAutoSaveValue = <T extends string | number>({
  currentValue,
  shouldSave,
  interval = AUTOSAVE_INTERVAL_MS,
  saveOnDestroy = false,
  saveFn
}: AutoSaveValueParams<T>) => {
  const debouncedSaveFn = useMemo(() => debounce(saveFn, interval), [saveFn, interval])

  useEffect(() => {
    if (shouldSave) {
      void debouncedSaveFn()
    }
    return () => {
      debouncedSaveFn.cancel()
    }
  }, [currentValue, debouncedSaveFn, shouldSave])

  // this is a workaround to save the value on component destroy
  // useRef is done so the effect is not recreated on every render and always have the most recent callback
  const saveOnDestroyHolder = useRef({shouldSave, saveOnDestroy, saveFn})
  useEffect(() => {
    saveOnDestroyHolder.current = {shouldSave, saveOnDestroy, saveFn}
  }, [saveOnDestroy, shouldSave, saveFn])
  useEffect(() => {
    return () => {
      const {shouldSave, saveOnDestroy, saveFn} = saveOnDestroyHolder.current
      if (shouldSave && saveOnDestroy) {
        void saveFn()
      }
    }
  }, [])
}
