import {
  UnitOfMeasurement,
  MeasurementType,
  Status,
  MaterialType,
  MaterialStorage
} from '@hconnect/common/types'

const defaultCreatedOn: string = '2019-02-15T22:00:00.000Z'
const deafultCurrentStorageLevel = {
  level: 0,
  measuredAt: defaultCreatedOn,
  createdAt: defaultCreatedOn
}

const getCurrentStorageLevel = (level: number, date: string) => ({
  currentStorageLevel: {
    level,
    measuredAt: date,
    createdAt: date
  }
})

type CreateMaterialStorageParams = Partial<
  Pick<
    MaterialStorage,
    | 'deadStock'
    | 'isOptimized'
    | 'isMeasured'
    | 'minimumStockLevel'
    | 'minimumWeekendStockLevel'
    | 'status'
    | 'createdOn'
    | 'updatedOn'
    | 'currentStorageLevel'
    | 'materialType'
  >
> &
  Pick<MaterialStorage, 'id' | 'name' | 'capacity' | 'materialId'>

const createMaterialStorage = ({
  id,
  name,
  capacity,
  materialId,
  materialType = MaterialType.Cement,
  deadStock = 0,
  isOptimized = true,
  isMeasured = true,
  minimumStockLevel = capacity * 0.95,
  minimumWeekendStockLevel = capacity * 0.9,
  updatedOn,
  status = updatedOn ? Status.Edited : Status.Created,
  createdOn = defaultCreatedOn,
  currentStorageLevel = deafultCurrentStorageLevel
}: CreateMaterialStorageParams): MaterialStorage => {
  return {
    id,
    name,
    materialType,
    capacity,
    deadStock,
    isOptimized,
    minimumStockLevel,
    minimumWeekendStockLevel,
    materialId,
    slackCostPerTonne: 0,
    isMeasured,
    measurementType: MeasurementType.Auto,
    status,
    createdOn,
    ...(updatedOn ? {updatedOn, updatedBy: 'Test User'} : {}),
    createdBy: 'Test User',
    currentStorageLevel,
    emailSyncDetails: null,
    datamartSyncDetails: null,
    unitOfMeasurement: UnitOfMeasurement.Tonnes
  }
}

const materialStorages: MaterialStorage[] = [
  createMaterialStorage({
    id: 17,
    name: '1',
    capacity: 1056.0,
    materialId: 100,
    deadStock: 50,
    ...getCurrentStorageLevel(903, '2019-08-16T06:12:00Z')
  }),

  createMaterialStorage({
    id: 18,
    name: '2',
    capacity: 1056.0,
    materialId: 20,
    deadStock: 150,
    ...getCurrentStorageLevel(840, '2019-08-16T06:11:00Z')
  }),
  createMaterialStorage({
    id: 19,
    name: '3',
    capacity: 1056.0,
    materialId: 40,
    deadStock: 50,
    minimumStockLevel: 1001,
    minimumWeekendStockLevel: 1200,
    ...getCurrentStorageLevel(0, '2019-08-16T06:09:00Z')
  }),
  createMaterialStorage({
    id: 20,
    name: '4',
    capacity: 1056.0,
    materialId: 110,
    deadStock: 150,
    ...getCurrentStorageLevel(940, '2019-08-16T06:08:00Z')
  }),
  createMaterialStorage({
    id: 21,
    name: '5',
    capacity: 1056.0,
    materialId: 10,
    deadStock: 50,
    ...getCurrentStorageLevel(729, '2019-08-16T06:06:00Z')
  }),
  createMaterialStorage({
    id: 22,
    name: '6',
    capacity: 1056.0,
    materialId: 60,
    deadStock: 150,
    ...getCurrentStorageLevel(400, '2019-08-16T06:13:00Z')
  }),
  createMaterialStorage({
    id: 23,
    name: '7',
    capacity: 1056.0,
    materialId: 70,
    isMeasured: false,
    deadStock: 50,
    ...getCurrentStorageLevel(486, '2019-08-16T06:23:00Z')
  }),
  createMaterialStorage({
    id: 24,
    name: '8',
    capacity: 1056.0,
    materialId: 130,
    deadStock: 150,
    materialType: MaterialType.NonReportedMaterials,
    ...getCurrentStorageLevel(101, '2019-08-16T06:28:00Z')
  }),
  createMaterialStorage({
    id: 25,
    name: '9',
    capacity: 4200,
    materialId: 50,
    deadStock: 50,
    ...getCurrentStorageLevel(3374, '2019-08-16T06:13:00Z')
  }),
  createMaterialStorage({
    id: 26,
    name: '10',
    capacity: 4066,
    materialId: 20,
    deadStock: 150,
    ...getCurrentStorageLevel(3479, '2019-08-16T06:12:00Z')
  }),
  createMaterialStorage({
    id: 27,
    name: '11',
    capacity: 6263,
    materialId: 80,
    deadStock: 50,
    ...getCurrentStorageLevel(5695, '2019-08-16T06:23:00Z')
  }),
  createMaterialStorage({
    id: 28,
    name: '12',
    capacity: 6263,
    materialId: 20,
    deadStock: 150,
    ...getCurrentStorageLevel(2678, '2019-08-16T05:13:00Z')
  }),
  createMaterialStorage({
    id: 29,
    name: '13',
    capacity: 4275,
    materialId: 90,
    deadStock: 50,
    ...getCurrentStorageLevel(3245, '2019-08-16T06:12:00Z')
  }),
  createMaterialStorage({
    id: 30,
    name: '14',
    capacity: 4275,
    materialId: 40,
    deadStock: 150,
    minimumStockLevel: 4001,
    minimumWeekendStockLevel: 4230,
    ...getCurrentStorageLevel(3590, '2019-08-16T05:23:00Z')
  }),
  createMaterialStorage({
    id: 900,
    name: '31',
    materialType: MaterialType.RawMaterials,
    capacity: 4500,
    materialId: 30,
    deadStock: 50,
    ...getCurrentStorageLevel(1250, '2019-08-16T05:12:00Z')
  }),
  createMaterialStorage({
    id: 901,
    name: '32',
    materialType: MaterialType.RawMaterials,
    capacity: 4000,
    materialId: 120,
    deadStock: 150,
    ...getCurrentStorageLevel(2000, '2019-08-16T05:21:00Z')
  }),
  createMaterialStorage({
    id: 902,
    name: '33',
    materialType: MaterialType.RawMaterials,
    capacity: 1000,
    materialId: 200,
    deadStock: 50,
    ...getCurrentStorageLevel(200, '2019-08-14T05:12:00Z')
  })
]

// eslint-disable-next-line import/no-default-export
export default materialStorages
