import {MaterialType} from '@hconnect/common/types'
import {Box} from '@mui/material'
import {FC, useMemo} from 'react'

import {RouteName} from '../../routing'
import {pageDataTestId} from '../../shared/formatutils'
import {fitElementsToGridBasedOnColSpan} from '../../shared/helpers/utils'
import {useCurrentStoragesWithMaterialQuery} from '../../shared/hooks/api'
import {useStockReachQuery} from '../../shared/hooks/api/kpi/useStockReachQuery'
import {useMediaQueryBreakpoints} from '../../shared/hooks/useMediaQueryBreakpoints'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {useSearchParams} from '../../shared/hooks/useSearchParams'
import {getStorageToMaterialAmount} from '../../shared/selectors/stock'

import {SiloCard} from './storages/StockCard/SiloCard'

export const PageSilos: FC = () => {
  const [obtainedParams] = useSearchParams('materialType')
  const obtainedMaterialType = obtainedParams.materialType
  if (obtainedMaterialType === null) {
    throw new Error('BUG: materialType search param should be set before rendering this component')
  }
  const selectedMaterialType = obtainedMaterialType as MaterialType

  const {sm, md, lg} = useMediaQueryBreakpoints()
  const plantConfig = usePlantConfig()
  const {data: storagesWithMaterial} = useCurrentStoragesWithMaterialQuery()

  if (!storagesWithMaterial) {
    throw new Error('Bug: storages with material should be loaded to render this component')
  }

  const columns = sm ? 1 : md ? 2 : lg ? 3 : 4

  const materialIds = useMemo(() => {
    return [...new Set(storagesWithMaterial.map((storage) => storage.materialId))]
  }, [storagesWithMaterial])
  const {data: stockReach} = useStockReachQuery({
    materialIds
  })

  const allMaterialTypesInfo = useMemo(
    () =>
      getStorageToMaterialAmount({
        storagesWithMaterial,
        stockReach
      }),
    [storagesWithMaterial, stockReach]
  )

  const materialsForTypeInfo = allMaterialTypesInfo.filter(
    (materialInfo) => materialInfo.materialType === selectedMaterialType
  )

  const materialInfoWithColSpan = materialsForTypeInfo.map((material) => ({
    colSpan: material.storages.length > 5 && columns > 1 ? 2 : 1,
    data: material
  }))
  const sortedMaterialInfo = fitElementsToGridBasedOnColSpan(columns, materialInfoWithColSpan)

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: `repeat(${columns}, 1fr)`
      }}
      {...pageDataTestId(RouteName.STOCK_SILOS)}
    >
      {sortedMaterialInfo.map(({data: material, colSpan}) => (
        <SiloCard
          key={material.materialId}
          materialInfo={material}
          timezoneId={plantConfig.timezone_id}
          colSpan={colSpan}
        />
      ))}
    </Box>
  )
}
