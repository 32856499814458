import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {numberRegEx, dateRegEx, saveScenario, sleepResponse} from './utils'

export const enableMaterialDemandEndpoints = (mock: MockAdapter) => {
  // GET material demand
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-demand/daily$`)).reply((config) => {
    const {from, to} = config.params as {from: string; to: string}
    const {
      burglengenfeld: {materialDemandByDay}
    } = mockStore.scenario()
    // from to are in local time
    const fromDay = moment(from)
    const toDay = moment(to)
    const filteredMaterialDemandByDay = Object.fromEntries(
      Object.entries(materialDemandByDay).filter(([day]) => {
        return moment(day).isBetween(fromDay, toDay, 'day', '[]')
      })
    )
    return sleepResponse([200, filteredMaterialDemandByDay])
  })

  const shippingTypePropertyRegEx = '(truck|ship|train)'

  // POST create material demand override
  mock
    .onPost(
      new RegExp(
        `^/plants/${numberRegEx}/material-demand/${numberRegEx}/demand/overrides/${dateRegEx}/shipping/${shippingTypePropertyRegEx}$`
      )
    )
    .reply((config) => {
      const {quantity} = JSON.parse(config.data) as {quantity: number}
      const materialId = (config.url as string).split('/')[4]
      const date = (config.url as string).split('/')[7]
      const shippingType = (config.url as string).split('/')[9] as 'train' | 'truck' | 'ship'

      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {materialDemandByDay}
      } = scenario

      // from to are in local time
      materialDemandByDay[date][materialId].merged[shippingType] = quantity
      materialDemandByDay[date][materialId].userOverrides[shippingType] = quantity
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })
  return mock
}
