import {dataTestId} from '@hconnect/uikit'
import {Box, Skeleton} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {ScheduleItemsContainer} from '../../../app/page-planning/dailyPlanning/scheduleCard/schedule'
import {TimeScaleFn, useLinearScale} from '../../helpers/scale'
import {toPixel} from '../../helpers/utils'
import {useElectricityPricesDataQuery} from '../../hooks/api'
import {ScheduleOtimizationRange} from '../../interfaces/api'

import {ChartHeaderDatetimeRows} from './ChartHeaderDateRow'
import {ElectricityPriceTopBar} from './electricityPriceTopBar/ElectricityPriceTopBar'
import {GanttChartRows} from './GantChartRows'
import {ChartNowIndicator} from './indicators/ChartNowIndicator'
import {ChartOptimizedPlanStartEndIndicator} from './indicators/ChartOptimizedPlanStartEndIndicator'

const VISIBLE_CELL_TEXT_BREAKPOINT = 24
const SCHEDULE_ROW_PADDING = 7

interface PlanningChartGanttProps {
  startOfPlan: Moment
  endOfPlan: Moment
  optimizationRange?: ScheduleOtimizationRange
  dateLabelHeight: number
  hourlyPriceHeight: number
  cellHeight: number
  cellWidth: number
  hoursDisplayed: number
  numberOfAssets: number | undefined
  xScale: TimeScaleFn
  hoursList: Moment[]
  isReadOnly: boolean
  shouldShowCurrentTime?: boolean
  shouldRoundCurrentTime?: boolean
  shouldShowExactPrices?: boolean
  shouldShowActualPricesIndicator?: boolean
  shouldShowColorCodedElectricityPrices?: boolean
  shouldShowFixedForOptimizerInfo?: boolean
  shouldShowStartEndTime?: boolean
  shouldShowScheduleItemComments?: boolean
}
export const _PlanningChartGantt = React.forwardRef<HTMLDivElement, PlanningChartGanttProps>(
  (
    {
      startOfPlan,
      endOfPlan,
      dateLabelHeight,
      hourlyPriceHeight,
      cellHeight,
      cellWidth,
      hoursDisplayed,
      xScale,
      hoursList,
      numberOfAssets,
      isReadOnly,
      optimizationRange,
      shouldShowCurrentTime = false,
      shouldRoundCurrentTime = false,
      shouldShowExactPrices = false,
      shouldShowActualPricesIndicator = false,
      shouldShowColorCodedElectricityPrices = false,
      shouldShowFixedForOptimizerInfo = false,
      shouldShowStartEndTime = false,
      shouldShowScheduleItemComments = false
    },
    ref
  ) => {
    const scheduleRowHeight = cellHeight - 2 * SCHEDULE_ROW_PADDING

    const {data: electricityPriceData} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])
    const {priceLevels, pricePerHour, mergedPrices: prices} = electricityPriceData ?? {}

    const ganttYScale = useLinearScale({
      domain: [0, numberOfAssets ?? 0],
      range: [0, cellHeight * (numberOfAssets ?? 0)]
    })

    const chartWidth = hoursDisplayed * cellWidth
    const isTextVisible = cellWidth > VISIBLE_CELL_TEXT_BREAKPOINT

    const shouldShowLoader = numberOfAssets === undefined

    const shouldShowPricesRow = shouldShowExactPrices || shouldShowColorCodedElectricityPrices

    return (
      <Box position="relative" width={chartWidth}>
        <ChartHeaderDatetimeRows
          cellWidth={cellWidth}
          isTextVisible={isTextVisible}
          dateLabelHeight={dateLabelHeight}
        />
        <ChartNowIndicator
          hoursDisplayed={hoursDisplayed}
          cellWidth={cellWidth}
          shouldRoundCurrentTime={shouldRoundCurrentTime}
        />
        {optimizationRange && (
          <ChartOptimizedPlanStartEndIndicator
            hoursDisplayed={hoursDisplayed}
            cellWidth={cellWidth}
            optimizationRange={optimizationRange}
          />
        )}
        <Box sx={{mt: toPixel(dateLabelHeight * 2)}}>
          {shouldShowPricesRow && (
            <ElectricityPriceTopBar
              hoursList={hoursList}
              shouldShowExactPrices={shouldShowExactPrices}
              shouldShowColorCodedPrices={shouldShowColorCodedElectricityPrices}
              shouldShowActualPricesIndicator={shouldShowActualPricesIndicator}
              hoursDisplayed={hoursDisplayed}
              isTextVisible={isTextVisible}
              hourlyPriceHeight={hourlyPriceHeight}
              pricePerHour={pricePerHour}
              cellWidth={cellWidth}
            />
          )}
        </Box>
        <Box sx={{width: chartWidth, height: 1, overflow: 'hidden', position: 'relative'}}>
          {shouldShowLoader ? (
            <Skeleton
              variant="rectangular"
              sx={{
                height: 1,
                width: hoursList.length * cellWidth
              }}
              {...dataTestId('planning_chart_gantt_skeleton')}
            />
          ) : (
            <GanttChartRows
              hoursList={hoursList}
              startOfPlan={startOfPlan}
              endOfPlan={endOfPlan}
              stepWidth={cellWidth}
              xScale={xScale}
              isReadOnly={isReadOnly}
              shouldShowColorCodedElectricityPrices={shouldShowColorCodedElectricityPrices}
              shouldRoundCurrentTime={shouldRoundCurrentTime}
              shouldShowCurrentTime={shouldShowCurrentTime}
            />
          )}
          <div
            // passing ref here to enable tranform translateX to move chart horizontally
            ref={ref}
            style={{
              display: 'flex',
              flexDirection: 'column',
              pointerEvents: 'none'
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: chartWidth,
                height: cellHeight * (numberOfAssets ?? 10)
              }}
            >
              {prices && !shouldShowLoader && (
                <ScheduleItemsContainer
                  startOfPlan={startOfPlan}
                  endOfPlan={endOfPlan}
                  priceLevels={priceLevels}
                  prices={prices}
                  scheduleRowHeight={scheduleRowHeight}
                  scheduleRowPadding={SCHEDULE_ROW_PADDING}
                  xScale={xScale}
                  yScale={ganttYScale}
                  isReadOnly={isReadOnly}
                  shouldShowFixedForOptimizerInfo={shouldShowFixedForOptimizerInfo}
                  shouldShowColorCodedElectricityPrices={shouldShowColorCodedElectricityPrices}
                  shouldShowExactElectricityPrices={shouldShowExactPrices}
                  shouldShowStartEndTime={shouldShowStartEndTime}
                  shouldShowScheduleItemComments={shouldShowScheduleItemComments}
                />
              )}
            </Box>
          </div>
        </Box>
      </Box>
    )
  }
)
export const PlanningChartGantt = React.memo(_PlanningChartGantt)
PlanningChartGantt.displayName = 'PlanningChartGantt'
