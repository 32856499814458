import moment, {Moment} from 'moment-timezone'

import {StockReachEntry, StockReachResponse} from '../interfaces/api'

export enum StockReachState {
  Future = 'future',
  Date = 'date'
}

export type StockReachValue =
  | {type: StockReachState.Date; date: Moment}
  | {type: StockReachState.Future; date: Moment}

export type StockReachData = Record<string, StockReachValue>

function getDateEntry(
  stockReachEntry: StockReachEntry,
  timezone_id: string,
  maxReach: Moment
): StockReachValue {
  if (!stockReachEntry.date) {
    return {type: StockReachState.Future, date: maxReach}
  }
  return {type: StockReachState.Date, date: moment.utc(stockReachEntry.date).tz(timezone_id)}
}

export const stockReachDataSelector = (
  response: StockReachResponse,
  timezone_id: string,
  maxReach: Moment
): StockReachData => {
  return Object.fromEntries(
    Object.entries(response.forecast).map(([materialId, stockReachEntry]) => {
      return [materialId, getDateEntry(stockReachEntry, timezone_id, maxReach)]
    })
  )
}
