import {MaterialType} from '@hconnect/common/types/enum.types'
import {useMemo} from 'react'

import {getProducedOrForecastedMaterialsIds} from '../../../selectors/assetCapacity'
import {getCombinedValueFromValuesPerShippingType} from '../../../selectors/materialDemand'
import {MomentRange} from '../../../selectors/time'
import {usePlantConfig} from '../../usePlantConfigData'
import {useProducedPerMaterial} from '../kpi/useProducedPerMaterial'
import {useMaterialDemandPerMaterialQuery} from '../materialDemand/useMaterialDemandPerMaterialQuery'
import {useHistoryMaterialsById} from '../materials/useHistoryMaterialsById'

type ProducedAndForecastedMaterial = {
  forecast?: number
  produced?: number
}

export type ProducedAndForecastedMaterials = {
  [materialId: string]: ProducedAndForecastedMaterial
}

interface UseProducedAndForecastedMaterialsParams {
  timeFrame: MomentRange
  materialTypes?: MaterialType[]
}

export const useProducedAndForecastedMaterials = ({
  timeFrame,
  // Default to Cement and Clinker
  materialTypes = [MaterialType.Cement, MaterialType.Clinker]
}: UseProducedAndForecastedMaterialsParams) => {
  const {timezone_id: timezoneId} = usePlantConfig()
  const {data: materialsById, isLoading: isLoadingMaterials} = useHistoryMaterialsById({timeFrame})
  const {data: producedByMaterialId, isLoading: isLoadingAssets} = useProducedPerMaterial(timeFrame)
  const {data: demandData, isLoading: isLoadingDemand} = useMaterialDemandPerMaterialQuery({
    timeFrame,
    timezoneId
  })

  const isLoading = isLoadingAssets || isLoadingDemand || isLoadingMaterials

  const data = useMemo<ProducedAndForecastedMaterials | undefined>(() => {
    if (producedByMaterialId && demandData && materialsById) {
      const forecastedMaterials = demandData

      const commonMaterialIds = getProducedOrForecastedMaterialsIds(
        producedByMaterialId,
        forecastedMaterials,
        materialsById,
        materialTypes
      )

      return commonMaterialIds.reduce<ProducedAndForecastedMaterials>((result, materialId) => {
        result[materialId] = {
          forecast:
            forecastedMaterials[materialId] !== undefined
              ? getCombinedValueFromValuesPerShippingType(forecastedMaterials[materialId].merged) ??
                0
              : undefined,
          produced: producedByMaterialId[materialId]
        }
        return result
      }, {})
    }

    return undefined
  }, [producedByMaterialId, demandData, materialsById, materialTypes])

  return {data, isLoading}
}
